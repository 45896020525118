import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import heartFill from '@iconify/icons-eva/heart-fill';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-2-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
import npmFill from '@iconify/icons-eva/npm-fill';
import instance from 'src/instance/instance';
import { tr } from 'faker/lib/locales';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


const getShouldHide = async () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  if (!userData || !userData.donor) return false;

  const data = {
    id: userData.donor
  };

  try {
    const response = await instance.post("./donors/getdonor", data);
    if (response.data.donor.status === "d35932f3-5cf8-4ce1-8bed-ca0faa7db726" ) { 
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error fetching donor data:', error);
    return true;
  }
};

const sidebarConfig = async () => { return [
  {
    title: 'dashboard',
    path: '/donors/dashboard/app',
    icon: getIcon(pieChart2Fill),
    // shouldHide:await getShouldHide()
  },
  {
    title: 'Genealogy',
    path: '/donors/dashboard/genealogy',
    icon: getIcon(personAddFill),
    shouldHide:await getShouldHide()

  },
  {
    title: 'Referral Bonus',
    path: '/donors/dashboard/referralBonus',
    icon: getIcon(npmFill),
    shouldHide:await getShouldHide()

  },
  {
    title: 'Health Benefits',
    path: '/donors/dashboard/healthbenefits',
    icon: getIcon(checkmarkCircleFill),
    // shouldHide:await getShouldHide()

  },
  {
    title: 'Invite a Donor',
    path: '/donors/dashboard/invitedonor',
    icon: getIcon(personAddFill),
    shouldHide:await getShouldHide()

  },
  {
    title: 'HMO ID',
    path: '/donors/dashboard/hxpid',
    icon: getIcon(heartFill),
    // shouldHide:await getShouldHide()

  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];
}

export default sidebarConfig;
