import React from "react";
import { Container, Grid, Typography, Box } from '@mui/material';

export default function AboutUs() {
  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom>
        About Hospital of the Holy Cross
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} className="text-center">
          <img
            className="img-fluid"
            width="100%"
            src="/static/website/old_picture.png"
            alt="Old Picture"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box p={2}>
            <Typography variant="h4" component="strong" gutterBottom>
              The Legacy
            </Typography>
            <Typography variant="body1" paragraph>
              The early months of 1962, young coupled migrated from Nabua Camarines Sur to the Municipality of Calapan City Oriental Mindoro. Both of them are Doctors of Medicine and brought their practice to their new hometown including the name of the clinic the Holy Cross Clinic named after the holy cross parish of Nabua.
            </Typography>
            <Typography variant="body1" paragraph>
              Dr Jose Priela, Jr UST Medicine Class 1957 was a native Nabueno with Dr. Milagros L.S. Medrano UST Medicine Class 1958 was born and raised in Calapan City.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2}>
            <Typography variant="body1" paragraph>
              They had two children after time Renato Jose and Thomas and the two later had their third boy Emmanuel. They practice quickly prospered at their J.P. Rizal Clinic address and so did the number of their children which number 6 by the end of 1966. Benjamin, Belinda, and Maria Melissa then came the big fire of 1972.
            </Typography>
            <Typography variant="body1" paragraph>
              They practice quickly prospered at their J.P. Rizal Clinic address and so did the number of their children which number 6 by the end of 1966. Benjamin, Belinda, and Maria Melissa then came the big fire of 1972. Then came the big fire of 1972, which gutted practically the entire business district including their clinic the Hospital Holy Cross Clinic. Then transferred to Ilaya at the garage of the Medrano house and adjacent property was purchased as an extension of the clinic to make it a five deadline. This became the first Medicare accredited facility in Oriental Mindoro in 1972.
            </Typography>
            <Typography variant="body1" paragraph>
              Despite the change of location, old patients followed their practice the clinic. In a garage was always jam-packed with people even on Sundays when the clinic was closed. In 1975 the Holy Cross Clinic was transferred to its present location and was accredited as a 24-bed hospital by the Medicare and Department of Health. Because of the hidden location and the bad state of the barangay roads, most of the patient's where the old ones from the two previous clinic sites.
            </Typography>
            <Typography variant="body1" paragraph>
              By 1985 the clinic has been expanded to double its precious size and was upgraded to secondary 30 bed private hospital status with operating rooms, delivery room and level 2 laboratory.
            </Typography>
            <Typography variant="body1" paragraph>
              The name was also change to Hospital of the Holy Cross in 1984 Dr Milagros Medrano Priela pass on after a 3-year battled with cancer. After that Dr Jose Priela, Jr. became semi-retired from his medical practice getting help from other doctors like Dr. Warlito Quitos and Dr. Sebi Gonzales.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box p={2}>
            <Typography variant="body1" paragraph>
              The early months of 1962, young coupled migrated from Nabua Camarines Sur to the Municipality of Calapan City Oriental Mindoro. Both of them are Doctors of Medicine and brought their practice to their new hometown including the name of the clinic the Holy Cross Clinic named after the holy cross parish of Nabua.
            </Typography>
            <Typography variant="body1" paragraph>
              Dr Jose Priela, Jr UST Medicine Class 1957 was a native Nabueno with Dr. Milagros L.S. Medrano UST Medicine Class 1958 was born and raised in Calapan City.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="text-center">
          <img
            className="img-fluid"
            width="100%"
            src="/static/website/new_picture.png"
            alt="New Picture"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
