import React, { useState } from "react";
import { Typography, Container, Grid, Box, Card, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import BusinessIcon from '@mui/icons-material/Business';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: "black",
  backgroundColor: "whitesmoke"
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

function Services() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <Container>
        <Typography variant="h3" className="mt-5 mb-5 page-title">
          Services
        </Typography>
        <br />
      </Container>
      <div className="team pt-5">
        <Container className="container3 container mt-3">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className="p-3">
              <RootStyle>
                <Box className="p-3 bg-light border border-white">
                  <Typography variant="h3" className="text-dark">
                    Direct Services
                  </Typography>
                  <List>
                    {['Medical Staff', 'Outpatient/In-patient', 'Laboratory Services', 'Radiology Services', 'Ultrasound Services', '2-D Echo', 'Rehab Med/P. T.'].map((service, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <MedicalServicesIcon />
                        </ListItemIcon>
                        <ListItemText primary={service} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </RootStyle>
            </Grid>
            <Grid item xs={12} md={6} className="p-3">
              <RootStyle>
                <Box className="p-3 bg-light border border-white">
                  <Typography variant="h3" className="text-dark">
                    Departments
                  </Typography>
                  <List>
                    {['Accounting', 'Billing', 'Central Supply', 'Dietary', 'Information', 'Pharmacy', 'Medical Records', 'Social Service', 'Philhealth', 'Maintenance', 'Chaplain'].map((department, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={department} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </RootStyle>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Services;