import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { Stack, TextField, IconButton, InputAdornment,Typography,Link,Select,MenuItem,Paper, Checkbox, Box,} from '@mui/material';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Typography, Box, Grid, Stack,Select,MenuItem } from '@mui/material';
import { formatName } from 'src/utils/formatName';
import env from 'src/env';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function Verify({row}) {
  const { getUser } = useAuthentication();
  const id = getUser().id

  const initialValues= {
    id:row.id,
    verifyBy:id,
  }

  const initialValuesReject= {
    donor_id:row.id,
    rejection_by:id,
    rejection_comments:'',
    rejection_type:"Verify Donor"
  }
  
  const [open, setOpen] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  const [reasons, setReasons] = React.useState([]);
  const [selectedReasonId, setSelectedReasonId] = React.useState('');

  const [data,setData] = React.useState(initialValues)
  const [dataReject,setDataReject] = React.useState(initialValuesReject)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');



  React.useEffect(()=> {
    instance.get("./reasons/reasons").then((response) => {
      setReasons(response.data)
      // setRanks(response.data)
    }) 
  },[])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVerify = async () => {

    try {
      // Make a POST request using Axios
      instance.post("./donors/verify",data).then((response) => {
        if (!response.data.error){
          console.log('Verification Completed:', response.data);
          setSnackbarMessage('Verification Completed Successfully');
          setSnackbarSeverity('success');
          window.location.reload();
        }else{
          console.error('Unsuccessful', response.data.error);
          setSnackbarMessage('Verification Failed: ' + response.data.error);
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      }) 
      handleClose()
    } catch (error) {
       console.error('Error creating post:', error.message);
    }

  }
  const handleOnChange = (value) => {
    setSelectedReasonId(value)
    setDataReject({ ...dataReject, rejection_comments: value })

  } 
  const handleReject = async () => {
    console.log(dataReject)
    // try {
    //   // Make a POST request using Axios
    //   instance.post("./donors/verify",data).then((response) => {
    //     if (!response.data.error){
    //       console.log('Verification Completed:', response.data);
          
    //     }else{
    //       console.error('Unsuccessful', response.data.error);
    //     }
    //   }) 
    //   handleClose()
    // } catch (error) {
    //    console.error('Error creating post:', error.message);
    // }

  }

// console.log(id)
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Verify Donor Account
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verify Donor Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Stack spacing={2}>
            <Stack spacing={2}>
            <Typography variant="h6"> Personal Details</Typography>
            <Grid item xs={12}>
              <Typography variant="h6">Name: {formatName(`${row.firstname} ${row.middlename} ${row.lastname}`)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Birthday: {row.birthday}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Address: {row.address}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Contact Number: {row.contactNumber}</Typography>
            </Grid>
        

            {/* dontation details */}
            <Grid item xs={12}>
              <Typography variant="h6"> Donatation Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Mode of donation: {row.M_O_D}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Amount: {row.amount}</Typography>
            </Grid>
            </Stack>
          </Stack>
          <br/>
          <Grid item xs={12}>
            <Stack spacing={2}>
            <Typography variant="h6">Valid ID (Personal Identification)</Typography>
            <Stack spacing={2}>
            <Box  component="img" src={`${env.REACT_APP_BACKEND}${row.validIdFile}`} sx={{ width: 1000, height: 1000 }} />
            
            </Stack>
          </Stack>
          </Grid>
          <Stack spacing={2}>
          <Typography variant="h6">Proof of Donation : {row.depositSlip}</Typography>
            <Stack spacing={2}>
            <Box  component="img" src={`${env.REACT_APP_BACKEND}${row.depositfile}`} sx={{ width: 1000, height: 1000 }} />
            
            </Stack>
          </Stack>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">Back</Button>
          <Button onClick={handleOpenReject} variant="outlined" color="warning">Reject</Button>
          <Button onClick={handleVerify} variant="contained" color="primary" autoFocus>
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      {/* Rejection Dialog */}
      <Dialog
        open={openReject}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reject Donor Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Select
              value={selectedReasonId}
              onChange={(event) => handleOnChange(event.target.value)}
              fullWidth
            >
              {reasons.map((reason) => (
                <MenuItem key={reason.id} value={reason.id}>
                  {reason.reason}
                </MenuItem>
              ))}
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReject}>Back</Button>
          <Button onClick={handleReject} disabled={!selectedReasonId}>Reject</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}