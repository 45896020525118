import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Divider, Link, Container, Paper } from '@mui/material';

const PrivacyPolicyModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  };

  const contentStyle = {
    overflowY: 'auto',
    padding: '16px',
    flex: '1 1 auto',
  };

  const footerStyle = {
    padding: '8px 16px',
    textAlign: 'right',
    borderTop: '1px solid #e0e0e0',
  };

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        View Privacy Policy
      </Button> */}
      <Link underline="always" sx={{ color: "text.primary" }} onClick={handleOpen}>
        Privacy Policy
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="privacy-policy-title"
        aria-describedby="privacy-policy-description"
      >
        <Box sx={modalStyle}>
          {/* Modal Header */}
          <Box sx={{ padding: '16px', borderBottom: '1px solid #e0e0e0' }}>
            <Typography id="privacy-policy-title" variant="h5" component="h2">
              Privacy Policy
            </Typography>
          </Box>

          {/* Modal Content */}
      <Container maxWidth="md" sx={contentStyle}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Your privacy is important to us. This Privacy Policy explains how we collect, use, share, and protect your information when you use our services.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect various types of information, including:
          - **Personal Information**: Your name, email, phone number, address, and other identifiable details.
          - **Usage Data**: Information about how you interact with our platform, such as pages visited, time spent, and preferences.
          - **Device and Browser Information**: IP address, browser type, operating system, and cookies to enhance your experience.
          - **Third-Party Data**: Information from social media and other services when you connect your account to ours.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information collected to:
          - Provide, operate, and personalize our services.
          - Process transactions and respond to customer support inquiries.
          - Improve platform security and detect fraud or malicious activities.
          - Send updates, promotional offers, and service notifications.
          - Conduct research and analytics to enhance user experience.
          - Comply with legal obligations and regulatory requirements.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          3. Sharing of Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell your personal data. However, we may share your information in the following situations:
          - **With Service Providers**: To help us manage our services, such as hosting, analytics, and payment processing.
          - **For Legal Reasons**: If required by law, court order, or legal process.
          - **Business Transfers**: In case of a merger, acquisition, or sale of assets.
          - **Aggregated Data**: With partners for insights and analytics without identifying individual users.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          4. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We take appropriate security measures to protect your personal information. These include:
          - Encryption and secure storage of sensitive data.
          - Access control and authentication measures.
          - Regular security audits and monitoring to prevent unauthorized access.
          However, no method of transmission over the Internet is 100% secure. We encourage you to use strong passwords and protect your account information.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          5. Your Rights and Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
          - **Access and Update**: Review and update your personal data through your account settings.
          - **Delete Your Data**: Request deletion of your account and personal data, subject to legal obligations.
          - **Opt-Out**: Choose to unsubscribe from marketing communications.
          - **Control Cookies**: Manage your cookie preferences via browser settings.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          6. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to enhance functionality and improve user experience. These include:
          - **Essential Cookies**: Necessary for site operation and security.
          - **Analytics Cookies**: Help us understand user behavior and improve the platform.
          - **Advertising Cookies**: Used to show personalized ads and measure campaign effectiveness.
          You can manage cookie preferences through your browser settings or opt out of targeted advertising via industry-standard tools.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          7. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We retain your data for as long as necessary to provide services and comply with legal obligations. Once data is no longer required, we securely delete or anonymize it.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          8. Changes to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy periodically to reflect changes in our practices. Significant changes will be communicated through email notifications or a notice on our website.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1">
          If you have any questions or concerns about this Privacy Policy, please contact us at:
          - **Email**: support@example.com
          - **Mailing Address**: 123 Privacy St, City, Country
        </Typography>
      </Paper>
    </Container>

          {/* Modal Footer */}
          <Box sx={footerStyle}>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Close
            </Button>
          </Box>
          
        </Box>
      </Modal>
    </div>
  );
};

export default PrivacyPolicyModal;
