import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, IconButton, InputAdornment,Typography,Link,Select,MenuItem,Paper, Checkbox, Box,Grid} from '@mui/material';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import { formatName } from 'src/utils/formatName';
import env from 'src/env';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Validate({row}) {
  const { getUser } = useAuthentication();
  const id = getUser().id

  const initialValues= {
    id:row.id,
    validateBy:id,
  }
  
  const [open, setOpen] = React.useState(false);
  const [data,setData] = React.useState(initialValues)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleValidate = async () => {

    try {
      // Make a POST request using Axios
      instance.post("./donors/validate",data).then((response) => {
        if (!response.data.error){
          console.log('Validation Completed:', response.data);
          setSnackbarMessage('Validation Completed Successfully');
          setSnackbarSeverity('success');
          window.location.reload();
        }else{
          console.error('Unsuccessful', response.data.error);
          setSnackbarMessage('Verification Failed: ' + response.data.error);
          setSnackbarSeverity('error');
        }
      }) 
      handleClose()
    } catch (error) {
       console.error('Error creating post:', error.message);
    }

  }

// console.log(id)
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Validate Donor Account
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verify Donor Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Stack spacing={2}>
            <Stack spacing={2}>
            <Typography variant="h6"> Personal Details</Typography>
            <Grid item xs={12}>
              <Typography variant="h6">Name: {formatName(`${row.firstname} ${row.middlename} ${row.lastname}`)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Birthday: {row.birthday}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Address: {row.address}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Contact Number: {row.contactNumber}</Typography>
            </Grid>
        

            {/* dontation details */}
            <Grid item xs={12}>
              <Typography variant="h6"> Donatation Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Mode of donation: {row.M_O_D}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Amount: {row.amount}</Typography>
            </Grid>
            </Stack>
          </Stack>
          <br/>
          <Grid item xs={12}>
            <Stack spacing={2}>
            <Typography variant="h6">Valid ID (Personal Identification)</Typography>
            <Stack spacing={2}>
            <Box  component="img" src={`${env.REACT_APP_BACKEND}${row.validIdFile}`} sx={{ width: 1000, height: 1000 }} />
            
            </Stack>
          </Stack>
          </Grid>
          <Stack spacing={2}>
          <Typography variant="h6">Proof of Donation : {row.depositSlip}</Typography>
            <Stack spacing={2}>
            <Box  component="img" src={`${env.REACT_APP_BACKEND}${row.depositfile}`} sx={{ width: 1000, height: 1000 }} />
            
            </Stack>
          </Stack>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">Back</Button>
          <Button 
          // onClick={handleOpenReject} 
          variant="outlined" color="warning">Reject</Button>
          <Button onClick={handleValidate} variant="contained" color="primary" autoFocus>
            Validate
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}