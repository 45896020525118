import { useState, useEffect } from 'react';
import { Container, Typography, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';

const TABLE_HEAD = [
  { id: 'donorName', label: 'Donor Name', alignRight: false },
  { id: 'bonus', label: 'Bonus', alignRight: false },
  { id: 'downlineDetails', label: 'Downline Details', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
//   { id: 'updatedAt', label: 'Updated At', alignRight: false }
];

export default function ReferralBonuses() {
  const { getUser } = useAuthentication();
  const [referralBonuses, setReferralBonuses] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const data = getUser().donor;
  
  useEffect(() => {
    const fetchReferralBonuses = async () => {
      if (!data) return;

      try {
        const response = await instance.get(`/bonus/referral-bonuses?donorId=${data}`);
        setReferralBonuses(response.data);
      } catch (error) {
        console.error('Error fetching referral bonuses:', error);
      }
    };

    fetchReferralBonuses();
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Referral Bonuses
      </Typography>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {referralBonuses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow hover key={row.id}>
                  <TableCell>{row.donorName}</TableCell>
                  <TableCell>{row.bonus}</TableCell>
                  <TableCell>
                    {row.downlineDetails.map((downline) => (
                      <div key={downline.downlineId}>
                        Level: {downline.level}, Name: {downline.downlineName}, Value: {downline.levelValue}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                  {/* <TableCell>{new Date(row.updatedAt).toLocaleString()}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={referralBonuses.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}