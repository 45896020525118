import React from "react";
import { Typography, Container, Grid, Box, Card, List, ListItem, ListItemText } from "@mui/material";
import { styled } from '@mui/material/styles';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(3)
}));

export default function EyeCenter() {
  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom>
        The EYE CENTER HOLY CROSS
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <RootStyle>
            <Typography variant="h5" gutterBottom>
              Services
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Computerized Eye Examination" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Laser & Microsurgery" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Cataract/ Lens Implant" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Glaucoma & Diabetic Eye" />
              </ListItem>
            </List>
          </RootStyle>
        </Grid>
        <Grid item xs={12} md={6}>
          <RootStyle>
            <Typography variant="h5" gutterBottom>
              Additional Services
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Retina & Vitreous Surgery" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Pediatric Ophthalmology" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Oculoplastic Surgery" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Contact Lens Services" />
              </ListItem>
            </List>
          </RootStyle>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} className="p-3 text-center">
          <br />
          <h1 className="page-title" style={{ align: 'center' }}>About Hospital of the Holy Cross</h1>
          <br />
          <Typography variant="h1" component="strong" className="text-info">
            {/* <a href="/" className="text-danger"> */}
            {/* About us */}
            {/* </a> */}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box p={{ xs: 1, md: 1 }}>
            <Typography variant="h3" component="strong">
              Our latest acquisitions:
            </Typography>
            <br />
            <br />

            <Typography variant="body1">
              <strong>Optical Coherence Tomography (OCT)</strong> is the latest imaging technology in ophthalmology that provides highly accurate images of the inside of the eye, 150x better than the best MRI or CT scan ever made. It utilizes laser interferometry to create cross-sectional map of the retina that is extremely precise to two (2) micron level, thereby also allowing more accurate evaluation and diagnosis of different eye disorders. OCT has changed the paradigm of assessment of the retina and revolutionized the management and diagnosis of eye diseases.
            </Typography>
            <br />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="p-3 text-center">
          <img
            className="img-fluid"
            width="100%"
            src="/static/website/oct.png"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box p={{ xs: 1, md: 1 }}>

            <Typography variant="body1">
              Lasers have been used for the treatment of various eye disorders: Glaucoma, diabetic eye, Central serous chorioretinopathy, macular edema, eye tumors, etc. Our machine, TruScan Pro Laser 577 (TPL577), provides major advantages over other systems:
            </Typography>
            <br />

            <Typography variant="body1">
              1) TPL577 utilizes the most advanced laser technology that delivers laser in micro pulses, which has been shown to be very safe & cause no tissue injury; unlike the older continuous wave lasers which burn and scar the retina.
            </Typography>
            <br />

            <Typography variant="body1">
              2) TPL577 emits Yellow Laser which has been shown to be gentle even on the very sensitive macular area - a “no fire zone" in older models.
            </Typography>
            <br />

            <Typography variant="body1">
              3) Conventional laser systems only produce "one hit per shot" while TPL577 can deliver precisely arranged "multiple hits per shot", making the procedure faster and providing better patient comfort.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="p-3 text-center">
          <img
            className="img-fluid"
            width="100%"
            src="/static/website/laser1.png"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box p={{ xs: 1, md: 1 }}>
            <Typography variant="body1">
              We have three (3) lasers available for various eye diseases.
              Automated Visual Field Analysis detects the earliest signs of glaucoma, retina, optic nerve & other brain conditions.
            </Typography>
            <br />
            <Typography variant="body1">
              Cataracts are done using Phacoemulsification, the most modern technology in cataract surgery.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="p-3 text-center">
          <img
            loading="Lazy"
            className="img-fluid"
            width="100%"
            src="/static/website/laser2.png"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
